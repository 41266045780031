import React, { useRef, useEffect, useState } from "react";
import { FaTimes, FaArrowLeft } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import TripDetailsPanel from "./TripDetailsPanel"
import Card from "../blog/Card";

  const MyGallery: React.FC<any> = ({ items, isFullscreen, setIsFullscreen }) => {

    const handleScreenChange = (fullScreen: any) => {
      setIsFullscreen(fullScreen);
    };

    const renderItem = (item: any) => {
      if (item.type === "video") {
        return (
          <div className="image-gallery-image flex justify-start items-start">
            <video
              className="overflow-auto object-contain"
              controls
              style={{
                maxHeight: isFullscreen ? "100vh" : "300px",
                height: isFullscreen ? "auto" : "300px",
                width: "100%",
                maxWidth: "100%",
                margin: "auto",
                display: "block",
              }}
            >
              <source
                src={item.original}
                type="video/mp4"
                className="h-full w-full"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }

      return (
        <div className="image-gallery-image flex justify-start items-start">
          <img
            src={item.original}
            alt={item.description}
            className="overflow-auto object-contain"
            style={{
              maxHeight: isFullscreen ? "100vh" : "300px",
              height: isFullscreen ? "auto" : "300px",
              width: "100%",
              maxWidth: "100vw",
              margin: "auto",
              display: "block",
            }}
          />
          {item.caption && (
            <span
              className="image-gallery-description"
              style={{ color: "white" }}
            >
              {item.caption}
            </span>
          )}
        </div>
      );
    };

    return (
      // <div className="w-full" style={{ height: isFullscreen ? '100vh' : '300px' }}>
      <ImageGallery
        items={items}
        renderItem={renderItem}
        onScreenChange={handleScreenChange}
        useBrowserFullscreen={false}
        showBullets={true}
        showPlayButton={false}
        showThumbnails={true}
      />
      // </div>
    );
  };

  function transformImages(images: any) {
    if (!images) return [];

    const renderedImages =
      images?.map((image: any) => ({
        type: "image",
        original: image.image_url || image.image_file,
        thumbnail: image.image_url || image.image_file,
        caption: image.caption || "",
      })) || [];


    return [...renderedImages];
  }


const SpotSidebar = ({ feature, onClose, poi, onReturn, tripsShown }) => {
    const { t } = useTranslation();

    const [visibleFeature, setVisibleFeature] = useState(poi ? poi : feature);
    const [isFullscreen, setIsFullscreen] = useState(false);


  useEffect(() => {
    setVisibleFeature(poi ? poi : feature)
  }, [feature, poi])

  if (!visibleFeature) return null;


  console.log('feature', visibleFeature)

  return (
    <div className="fixed top-[80px] right-3 rounded-lg w-full max-w-md h-[calc(100vh-120px)] bg-white shadow-xl z-50 flex flex-col overflow-y-auto transition-transform transform translate-x-0">
      {/* Header */}
      <div className="flex items-center justify-between p-4 pb-2 pt-2">
        <h2 className="text-lg font-semibold text-gray-800">{visibleFeature.title}</h2>
        {poi ? (
        <IconButton
        onClick={onReturn}
      >
        <FaArrowLeft size={20} />
      </IconButton>
        ) : (
            <IconButton
            onClick={onClose}
          >
            <FaTimes size={20} />
          </IconButton>
        )}

      </div>

      {/* Image Carousel */}
      {visibleFeature.images && visibleFeature.images.length > 0 && (
        <div className="relative w-full mb-4">
          <MyGallery
            items={transformImages(visibleFeature.images)}
            isFullscreen={isFullscreen}
                          setIsFullscreen={setIsFullscreen}
            />
        </div>
      )}

<div className="px-4">
      <TripDetailsPanel
mainPoiId={visibleFeature.id}
tripsShown={tripsShown}
      />
      </div>

      {/* HTML Content */}
      <div
        className="p-4 text-gray-700 space-y-4"
        dangerouslySetInnerHTML={{ __html: visibleFeature.content }}
      />

      {/* Relevant Trips */}
      {visibleFeature.relevant_trips && visibleFeature.relevant_trips.length > 0 && (
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-4">{t('spot.relevant-trips')}</h3>
          <div className="space-y-4">
            {visibleFeature.relevant_trips.map((trip, index) => (
              <Link to={`/${trip.author.username}/${trip.id}`} key={index}>
              <Card
              blog={trip}
              />
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpotSidebar;
