// api.js
import axios from "axios";
import i18next from 'i18next';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = '/api';

axios.defaults.withCredentials = true;

const api = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    // Attach the token if it exists
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    // Set the default Accept-Language header
    config.headers["Accept-Language"] = i18next.language || "en"; // Default to 'en' if no language is set

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const fetcher = (url) => {
  // Ensure the latest token is always used for each request
  const token = localStorage.getItem("token");
  return api
    .get(url, {
      headers: token ? { Authorization: `Token ${token}` } : {},
    })
    .then((res) => res.data);
};

const loginBackend = (username, password) => {
  return api
    .post(`/members/api-token-auth/`, {
      username: username,
      password: password,
    })
    .then((response) => {
      // Store the token in local storage
      localStorage.setItem("token", response.data.token);
      return true; // Indicate success
    })
    .catch((error) => {
      console.error("Error during auth", error);
      return false; // Indicate failure
    });
};

export const logout = () => {
  return api
    .post("/members/logout/")
    .then((response) => {
      // Assume logout always succeeds; clear the token
      localStorage.removeItem("token");
      return true; // Logout successful
    })
    .catch((error) => {
      console.error("Logout failed", error);
      return false; // Logout failed
    });
};

const apiCreateUser = (email, username, password, firstName, lastName) => {
  return api
    .post(`/members/register/`, {
      email: email,
      username: username,
      password: password,
      first_name: firstName,
      last_name: lastName,
    })
    .then((response) => {
      // Store the token in local storage
      localStorage.setItem("token", response.data.token);
      return true; // Indicate success
    })
    .catch((error) => {
      console.error("Error during auth", error);
      return false; // Indicate failure
    });
};

export const activateEmailAPI = (uid, token) => {
  return api
    .post(`/members/verify-email/`, { uid, token })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const fetchCurrentUser = () => {
  const token = localStorage.getItem("token");
  return api
    .get("/members/current-user/", {
      headers: token ? { Authorization: `Token ${token}` } : {},
    })
    .then((response) => response.data);
};

export const searchUsers = async (query) => {
  return api
    .get(`/members/community/search-users/`, { params: { q: query } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const updateUser = (id, data) => {
  return api
    .put(`/members/update/${id}/`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const uploadProfilePicture = async (id, data) => {
  const formData = new FormData();

  // Append the header image if available
  if (data.profile_picture) {
    const uniqueHeaderImageName = `header_${Date.now()}_${
      data.profile_picture.name
    }`;

    formData.append(
      "profile_picture",
      data.profile_picture,
      uniqueHeaderImageName
    );
    formData.append("user_id", id);
  }

  // Assuming you have an endpoint that accepts the blog ID in the URL
  return api
    .post(`/members/upload-profile-pic/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

const fetchUser = (id) => {
  return api
    .get(`/members/user/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const checkFollowStatus = (id) => {
  return api
    .get(`/members/community/check-follow-status/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const followUser = (id) => {
  return api
    .post(`/members/community/follow/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const unfollowUser = (id) => {
  return api
    .post(`/members/community/unfollow/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const RemoveFollowUser = (id) => {
  return api
    .post(`/members/community/remove/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const SetFolloSeenUser = (id) => {
  return api
    .post(`/members/community/follow-seen/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const aproveFollowUser = (id) => {
  return api
    .post(`/members/community/approve/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchFollowView = () => {
  return api
    .get(`/members/community/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchMaintenance = () => {
  return api.get("/members/maintenance/").then((response) => response.data);
};

const createBlogPost = (blogData) => {
  return api
    .post(`/blogs/new/`, {
      title: blogData.title,
      author: blogData.author,
      header_image: blogData.headerImage,
      content: blogData.content,
      visibility: blogData.blogVisibility,
      summary: blogData.summary,
      activities: blogData.activities,
      route: blogData.route,
      number_of_days: blogData.days,
    })
    .then((response) => response.data);
};

const updateBlogPost = (id, blogData) => {
  return api
    .put(`/blogs/update/${id}/`, {
      title: blogData.title,
      author: blogData.author,
      header_image: blogData.headerImage,
      content: blogData.content,
      visibility: blogData.blogVisibility,
      summary: blogData.summary,
      header_image_url: blogData.imageURL,
      header_image_attrib: blogData.imageCredit,
      activities: blogData.activities,
      route: blogData.route,
      number_of_days: blogData.days,
    })
    .then((response) => response.data);
};

api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    console.error("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Check if it's a 429 Too Many Requests response:
    if (error.response && error.response.status === 429) {
      // Here you can add your logic to handle the lockout
      // For example, redirect the user to a lockout page or set a state
      window.location = "/lockout"; // Redirect to a lockout page
    }
    return Promise.reject(error);
  }
);

const fetchActivities = () => {
  return api.get("/activities/").then((response) => response.data);
};

export const fetchPrioritizedUsers = () => {
  return api.get('/p/users/').then(response => response.data);
};


const fetchBlogs = ({ daysRange, activities, visibility, location, distance, from_author }) => {
  // Prepare the request payload
  const data = {
    startDays: daysRange[0],
    endDays: daysRange[1],
    activities: activities.join(","),
    visibility: visibility,
    distance: distance,
    from_author: from_author
  };
  if (location && location.length === 2) {
    data.lng = location[0];
    data.lat = location[1];
  }

  return api.post(`/blogs/`, data).then((response) => response.data);
};
export const fetchListBlogs = ({ daysRange, activities, visibility }) => {
  // Prepare the request payload
  const data = {
    startDays: daysRange[0],
    endDays: daysRange[1],
    activities: activities.join(","),
    visibility: visibility,
  };

  return api.post(`/blogs-list/`, data).then((response) => response.data);
};

export const fetchBlogsByIds = ({ ids }) => {
  // Prepare the request payload
  const data = {
    ids: ids.join(","),
  };

  return api.post(`/blogs/ids/`, data).then((response) => response.data);
};

const fetchBlogById = (id) => {
  return api.get(`/blogs/${id}/`).then((response) => response.data);
};

const fetchBlogByAuthor = (id) => {
  return api.get(`/blogs/author/${id}/`).then((response) => response.data);
};

const deleteBlogPost = (id) => {
  return api
    .delete(`/blogs/delete/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const fetchMunicipalityAreas = (lat, lng) => {
  return api
    .post(`/municipality/area/`, { lat: lat, lng: lng })
    .then((response) => response.data);
};

export const fetchMunicipalityRouteById = (id) => {
  return api
    .get(`/municipality/route/${id}/`)
    .then((response) => response.data);
};

export const createMunicipalityRoute = (routeData) => {
  return api
    .post(`/municipality/route/new/`, {
      title: routeData.title,
      header_image_url: routeData.imageURL,
      content: routeData.content,
      activities: routeData.activities,
      geometry: routeData.geometry,
      municipality: 320,
    })
    .then((response) => response.data);
};

export const updateMunicipalityRoute = (id, routeData) => {
  return api
    .put(`/municipality/route/update/${id}/`, {
      title: routeData.title,
      header_image_url: routeData.headerImage,
      content: routeData.content,
      activities: routeData.activities,
      geometry: routeData.geometry,
      municipality: 320,
    })
    .then((response) => response.data);
};

export const fetchMunicipalActivities = () => {
  return api.get("/municipality/activities/").then((response) => response.data);
};

export const fetchMunicipalityRoutes = () => {
  return api.get(`/municipality/route/`).then((response) => response.data);
};

export const fetchMunicipalityRoutesByAuthor = (id) => {
  return api
    .get(`/municipality/route/author/${id}/`)
    .then((response) => response.data);
};

export const deleteMunicipalityRoute = (id) => {
  return api
    .delete(`/municipality/route/delete/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchMunicipalityPOI = () => {
  return api.get(`/municipality/poi/`).then((response) => response.data);
};

export const fetchNewBlogById = (id, code) => {
  const params = code ? { code } : {};
  return api.get(`/blogs/${id}/`, { params }).then((response) => response.data);
};

export const fetchBlogPOIs = () => {
  return api.get(`/blogs/pois/`).then((response) => response.data);
};

export const createOrUpdateBlog = async (blogData) => {
  // Determine the appropriate endpoint and HTTP method (POST for create, PUT for update)
  const endpoint = blogData.id
    ? `/blogs/update/${blogData.id}/`
    : "/blogs/new/";
  const method = blogData.id ? "put" : "post";

  // Exclude images from the submission
  const { headerImage, ...submitData } = blogData;

  return api({
    url: endpoint,
    method: method,
    data: submitData,
  }).then((response) => response.data);
};

export const uploadBlogImages = async (
  newBlogData,
  chaptersWithImages,
  onProgress
) => {
  const formData = new FormData();

  console.log('chaptersWithImages', chaptersWithImages)


  formData.append("blog_id", newBlogData.id);

  // Append the header image if available
  if (chaptersWithImages.headerImage) {
    const uniqueHeaderImageName = `header_${Date.now()}_${
      chaptersWithImages.headerImage.name
    }`;

    formData.append(
      "header_image",
      chaptersWithImages.headerImage,
      uniqueHeaderImageName
    );
  }

  // Map chaptersWithImages to include the chapter ID from newBlogData
  newBlogData.chapter_ids.forEach((chapter_id, index) => {
    // Assuming chaptersWithImages is in the same order as newBlogData chapters
    const images = chaptersWithImages.chapters[index].images; // Images are based on the order
    images.forEach((image, imageIndex) => {
      formData.append(`chapter_ids`, chapter_id);
      formData.append(
        `chapter_updated`,
        chaptersWithImages.chapters[index].is_updated || false
      );
      formData.append(`poi_ids`, "null");

      if (image.image_url) {
        formData.append(`image_urls`, image.image_url);
        formData.append(`media_types`, image.type);
        formData.append(`captions`, image.caption);
        // Appending a string 'null' because FormData cannot handle null as a blob/file
        formData.append(`images`, new Blob([]), "null");
      } else {
        const uniqueImageName = `${chapter_id}_${Date.now()}_${imageIndex}_${
          image.file.name
        }`;
        // Append images with chapter ID from newBlogData
        formData.append(`images`, image.file, uniqueImageName);
        formData.append(`image_urls`, "");
        formData.append(`media_types`, "");
        formData.append(`captions`, image.caption);
      }
    });
  });

  newBlogData.chapter_ids.forEach((chapter_id, chapterIndex) => {
    const pois = chaptersWithImages.chapters[chapterIndex].chapter_poi || [];
    console.log('pois', pois)
    const poiIds = newBlogData.poi_ids[chapter_id] || [];
    console.log('newblog pois', pois)

    poiIds.forEach((poi_id, poiIndex) => {
      let poiImages = [];
      // Check if the current poi_index is valid and has images
      if (pois[poiIndex] && pois[poiIndex].images) {
        poiImages = pois[poiIndex].images;
      }

      if (poiImages.length > 0) {
        console.log("chapter poi", chapter_id, poiImages);
        poiImages.forEach((image, imageIndex) => {
          formData.append(`chapter_ids`, chapter_id);
          formData.append(`chapter_updated`, chaptersWithImages.chapters[chapterIndex].is_updated || false);

          formData.append("poi_ids", poi_id);
          console.log("poi image", image);

          if (image.image_url) {
            formData.append("image_urls", image.image_url);
            formData.append("media_types", image.type);
            formData.append("captions", "");
            formData.append("images", new Blob([]), "null");
          } else if (image.file) {
            const uniqueImageName = `poi_${poi_id}_${Date.now()}_${imageIndex}_${
              image.file.name
            }`;
            formData.append("images", image.file, uniqueImageName);
            formData.append("image_urls", "");
            formData.append("media_types", "");
            formData.append("captions", "");
          }
        });
      }
    });
  });

  // Assuming you have an endpoint that accepts the blog ID in the URL
  return api
    .post(`/blogs/upload-images/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        if (total) {
          // Calculate progress as a percentage
          let progress = Math.round((loaded * 100) / total);
          // Call onProgress callback function
          onProgress(progress, loaded, total);
        } else {
          console.log("Total size is not computable.");
        }
      },
    })
    .then((response) => response.data);
};


export const LikeBlog = (id) => {
  return api.post(`/blogs/${id}/like/`).then((response) => response.data);
};

export const apiUpdateContributor = (id, action, contributor) => {
  return api
    .post(`/blogs/${id}/update-contributor/`, { action, contributor })
    .then((response) => response.data);
};

export const fetchSongsAPI = (query) => {
  return api
    .post(`/external/spotify-search/`, { query })
    .then((response) => response.data);
};

export const fetchStravaActivitiesAPI = (query) => {
  return api
    .post(`/external/strava/activities/`, { query })
    .then((response) => response.data);
};

export const fetchStravaActivityDetailAPI = (activityId) => {
  return api
    .get(`/external/strava/activities/${activityId}/`)
    .then((response) => response.data);
};

export const updateStravaUserAPI = (query) => {
  return api
    .post(`/external/strava/update/`, { query })
    .then((response) => response.data);
};

export const updateWahooUserAPI = (query) => {
  return api
    .post(`/external/wahoo/update/`, { query })
    .then((response) => response.data);
};

export const fetchWahooActivitiesAPI = (query) => {
  return api
    .post(`/external/wahoo/activities/`, { query })
    .then((response) => response.data);
};

export const fetchWahooActivityDetailAPI = (workouts, activityId) => {
  return api
    .post(`/external/wahoo/activities/${activityId}/`, {workouts})
    .then((response) => response.data);
};

export const fetchCommunityLocations = () => {
  return api
    .post("/members/community/locations/")
    .then((response) => response.data)
    .catch((error) => {
      console.error(
        "There was an error fetching the community locations!",
        error
      );
    });
};

export const updateCurrentLocation = (latitude, longitude) => {
  return api
    .post("/members/community/update-location/", { latitude, longitude })
    .then((response) => response.data)
    .catch((error) => {
      console.error(
        "There was an error fetching the community locations!",
        error
      );
    });
};

export const updateCurrentTripAPI = (blog_id) => {
  return api
    .post(`/members/community/update-current-trip/`, { blog_id })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchRouteElevation = (id) => {
  return api
    .get(`/blogs/elevation/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchGPXTrack = (type, segmentId) => {

  return api
    .get(`/${type}/route-segment/${segmentId}/download-gpx/`)
    .then((response) => response.data);
};

export const proxyImageAPI = (url) => {
  return api
    .post(`/external/proxy-image/`, { url }, { responseType: "blob" })
    .then((response) => response.data); // Return the response data as a Blob
};
export const boatGeometryAPI = (start_lat, start_lon, end_lat, end_lon) => {
  return api
    .post(`/external/boat-geometry/`, {
      start_lat,
      start_lon,
      end_lat,
      end_lon,
    })
    .then((response) => response.data); // Return the response data as a Blob
};

export const fetchBlogFeed = () => {
  return api
    .get(`/blogs/blog-and-feed/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export {
  fetcher,
  loginBackend,
  apiCreateUser,
  createBlogPost,
  fetchBlogs,
  fetchBlogById,
  deleteBlogPost,
  updateBlogPost,
  fetchCurrentUser,
  updateUser,
  fetchBlogByAuthor,
  fetchUser,
  fetchActivities,
};

export const createQuestion = (blogId, newQuestion) => {
  return api.post(`/social/questions/${blogId}/`, { question_text: newQuestion })
    .then((response) => response.data);
};

export const fetchBlogQuestions = async (blogId) => {
  try {
    const response = await api.get(`/social/questions/${blogId}/`);
    return response.data; // Return the list of questions
  } catch (error) {
    console.error("Error fetching questions:", error);
    throw error;
  }
};


// Feedback

export const fetchFeedback = () => {
  return api.get(`/feedback/`).then((response) => response.data);
};

// Create new feedback
export const createFeedback = (newFeedback) => {
  return api.post(`/feedback/`, newFeedback).then((response) => response.data);
};

// Upvote feedback
export const upvoteFeedback = (feedbackId) => {
  return api.post(`/feedback/${feedbackId}/upvote/`).then((response) => response.data);
};

// Add a new comment to feedback
export const addComment = (feedbackId, comment) => {
  return api.post(`/feedback/${feedbackId}/comment/`, { text: comment }).then((response) => response.data);
};

// Update feedback status
export const updateFeedbackStatus = (feedback) => {
  return api.put(`/feedback/${feedback.id}/`, feedback).then((response) => response.data);
};

export const addReaction = (commentId, reactionType) => {
  return api.post(`/feedback/reaction/${commentId}/`, { reaction_type: reactionType }).then((response) => response.data);
};




//  SPOTS

export const fetchTags = () => {
  return api.get('/spot/tags/').then(response => response.data);
};

export const fetchSpotPOIs = () => {
  return api.get(`/spot/limited-pois/`).then((response) => response.data);
};

export const fetchStartCities = () => {
  return api.get('/spot/start-cities/').then(response => response.data);
};


export const addSpotTrip = async (tripData) => {
  // Determine the appropriate endpoint and HTTP method (POST for create, PUT for update)
  const endpoint = `/spot/add-trip/`
  const method = "post";

  return api({
    url: endpoint,
    method: method,
    data: tripData,
  }).then((response) => response.data);
};

export const createOrUpdateSpot = async (spotData) => {
  // Determine the appropriate endpoint and HTTP method (POST for create, PUT for update)
  const endpoint = spotData.id
    ? `/spot/update/${spotData.id}/`
    : "/spot/new/";
  const method = spotData.id ? "put" : "post";

  // Exclude images from the submission

  return api({
    url: endpoint,
    method: method,
    data: spotData,
  }).then((response) => response.data);
};

export const uploadSpotImages = async (
  newSpot,
  newSpotImages,
  onProgress
) => {
  const formData = new FormData();


  formData.append("blog_id", newSpot.id);


    // Assuming chaptersWithImages is in the same order as newSpot chapters
    const images = newSpotImages.images; // Images are based on the order
    images.forEach((image, imageIndex) => {
      formData.append(
        `updated`,
        newSpotImages.is_updated || false
      );
      formData.append(`poi_ids`, "null");

      if (image.image_url) {
        formData.append(`image_urls`, image.image_url);
        formData.append(`media_types`, image.type);
        formData.append(`captions`, image.caption);
        // Appending a string 'null' because FormData cannot handle null as a blob/file
        formData.append(`images`, new Blob([]), "null");
      } else {
        const uniqueImageName = `${newSpot.id}_${Date.now()}_${imageIndex}_${
          image.file.name
        }`;
        // Append images with chapter ID from newSpot
        formData.append(`images`, image.file, uniqueImageName);
        formData.append(`image_urls`, "");
        formData.append(`media_types`, "");
        formData.append(`captions`, image.caption);
      }
    });

    const pois = newSpotImages.secondary_pois || [];
    console.log('pois', pois)
    const poiIds = newSpot.poi_ids || [];
    console.log('newblog pois', pois)

    poiIds.forEach((poi_id, poiIndex) => {
      let poiImages = [];
      // Check if the current poi_index is valid and has images
      if (pois[poiIndex] && pois[poiIndex].images) {
        poiImages = pois[poiIndex].images;
      }

      if (poiImages.length > 0) {
        poiImages.forEach((image, imageIndex) => {
          formData.append(`updated`, newSpotImages.is_updated || false);

          formData.append("poi_ids", poi_id);
          console.log("poi image", image);

          if (image.image_url) {
            formData.append("image_urls", image.image_url);
            formData.append("media_types", image.type);
            formData.append("captions", "");
            formData.append("images", new Blob([]), "null");
          } else if (image.file) {
            const uniqueImageName = `poi_${poi_id}_${Date.now()}_${imageIndex}_${
              image.file.name
            }`;
            formData.append("images", image.file, uniqueImageName);
            formData.append("image_urls", "");
            formData.append("media_types", "");
            formData.append("captions", "");
          }
        });
      }
    });


  // Assuming you have an endpoint that accepts the blog ID in the URL
  return api
    .post(`/spot/upload-images/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        if (total) {
          // Calculate progress as a percentage
          let progress = Math.round((loaded * 100) / total);
          // Call onProgress callback function
          onProgress(progress, loaded, total);
        } else {
          console.log("Total size is not computable.");
        }
      },
    })
    .then((response) => response.data);
};

export const fetchSpots = () => {
  return api.get('/spot/').then(response => response.data);
};

export const fetchSpotRouteElevation = (id) => {
  return api
    .get(`/spot/elevation/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};


export const fetchSpotTrips = () => {
  return api.get('/spot/trips/').then(response => response.data);
};



