import React, { useEffect, useRef, useState, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FeatureCollection, Feature, LineString, Geometry } from "geojson";
import * as turf from "@turf/turf";
import booleanIntersects from "@turf/boolean-intersects";
import { useTranslation } from "react-i18next";
import polyline from "@mapbox/polyline";
import { Button, InputAdornment, TextField, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Close } from "@mui/icons-material";
import Chart from "chart.js/auto";
import {Tooltip as TooltipUI} from "@nextui-org/tooltip";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";

import Navbar from "../components/main/Navbar";
import SpotSidebar from "../components/spots/SpotSidebar";
import FilterMenu from "../components/spots/FilterMenu";
import { transportationColors } from "../utils/mapStyles";
import { createGeoJSONLine } from "../components/map/utils";
import { downloadGPX } from "../utils/utils";
import {
  fetchSpots,
  fetchSpotTrips,
  fetchSpotRouteElevation,
} from "../utils/api/api";
import LoadingPage from "./Loading";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

const fitBoundsToFeatures = (map, mapFeatures, markers) => {
  const allCoords = [];

  // Collect marker coordinates
  if (markers && markers.length > 0) {
    markers.forEach((marker) => {
      console.log("marker: ", marker);
      if (marker.getLngLat()) {
        const lngLat = marker.getLngLat();
        allCoords.push([lngLat.lng, lngLat.lat]);
      }
    });
  }

  // Collect GeoJSON feature coordinates
  if (mapFeatures && mapFeatures.features && mapFeatures.features.length > 0) {
    mapFeatures.features.forEach((feature) => {
      const { geometry } = feature;
      allCoords.push(...geometry.coordinates);
    });
  }

  // Fit bounds if there are any coordinates
  if (allCoords.length > 0) {
    const bounds = allCoords
      .reduce(
        (bounds: any, coord: any) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(allCoords[0], allCoords[0])
      )
      .toArray();

    map.fitBounds(bounds, {
      padding: 20,
      duration: 750,
    });
  } else {
    console.warn("No coordinates to fit bounds.");
  }
};

const ClimbingSpotsMap: React.FC<any> = ({
  map,
  mapContainerRef,
  allLayers,
  setAllLayers,
  allMarkers,
  setAllMarkers,
  mapAnimation,
  setMapAnimation,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [isLoading, setiÎsLoading] = useState<boolean>(true);
  const [geoData, setGeoData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedPoi, setSelectedPoi] = useState(null);
  const [routeSegmentsGeoJSON, setRouteSegmentsGeoJSON] = useState(null);
  const [secondaryPOIs, setSecondaryPOIs] = useState([]);
  const [modalImageVisible, setModalImageVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [modalImages, setModalImages] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [filteredGeoData, setFilteredGeoData] = useState([]);
  const [trips, setTrips] = useState([]);
  const [tripsShown, setTripsShown] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [markerOthers, setMarkerOthers] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [elevationData, setElevationData] = useState<any>(null);
  const showElevationRef = useRef<any>(null);
  const chartInstanceRef = useRef(null);
  const chartRef = useRef(null);
  const [gpxData, setGpxData] = useState(null);
  const markersdistanceRef = useRef([]);
  const [elevationSummary, setElevationSummary] = useState<any[]>([
    null,
    null,
    null,
  ]);

  useEffect(() => {
    if (map) {
      if (map.isStyleLoaded()) {
        setMapInstance(map);
      } else {
        map.on("load", () => {
          setMapInstance(map);
        });
      }

      map.off("load");
    }
  }, [map, mapContainerRef]);

  useEffect(() => {
    const resetting = async (map: any) => {
      await cleanUpMap(map);

      map.off("click");
      map.off("mouseenter");
      map.off("mouseleave");
      map.off("moveend");
      map.off("move");
      map.off("style.load");
      map.off("idle");

      map.resize();
      map.easeTo({ center: [7, 48], zoom: 4.3 });
    };

    if (mapInstance) {
      resetting(mapInstance);
    }
  }, [mapInstance]);

  const desiredStyleUrl = "mapbox://styles/vladke99/cm33k6g55002o01nwhohygudi";
  const desiredStyle = "cm33k6g55002o01nwhohygudi";

  const resetStyleAndFeatures = async (mapInstance: any) => {
    mapInstance.off("click");
    mapInstance.off("mouseenter");
    mapInstance.off("mouseleave");
    mapInstance.off("move");
    mapInstance.off("moveend");
    mapInstance.off("style.load");
    mapInstance.off("render");

    // if (
    //   !mapInstance.getStyle().sprite ||
    //   !mapInstance.getStyle().sprite.includes(desiredStyle)
    // ) {
    //   mapInstance.setStyle(desiredStyleUrl);
    // }
  };

  const removeSourcesAndLayers = async (
    mapInstance,
    layerNames = [],
    sourceNames = []
  ) => {
    try {
      const allLayers = mapInstance.getStyle().layers;
      const allSources = Object.keys(mapInstance.getStyle().sources);

      console.log("All layers before cleanup:", allLayers);

      // First, remove all layers associated with "slostr" or specified in `layerNames`
      allLayers.forEach((layer) => {
        if (
          (layer.id.includes("slostr") || layerNames.includes(layer.id)) &&
          mapInstance.getLayer(layer.id)
        ) {
          console.log(`Removing layer: ${layer.id}`);
          mapInstance.removeLayer(layer.id);
        }
      });

      // Then, remove all sources associated with "slostr" or specified in `sourceNames`
      allSources.forEach((sourceId) => {
        if (
          (sourceId.includes("slostr") || sourceNames.includes(sourceId)) &&
          mapInstance.getSource(sourceId)
        ) {
          console.log(`Removing source: ${sourceId}`);
          mapInstance.removeSource(sourceId);
        }
      });

      // Finally, remove all markers
      if (mapInstance._markers && Array.isArray(mapInstance._markers)) {
        console.log(`Removing ${mapInstance._markers.length} markers`);
        mapInstance._markers.forEach((marker) => {
          marker.remove();
        });
        // Clear the markers array to ensure there are no lingering references
        mapInstance._markers = [];
      }
    } catch (error) {
      console.error("Error during layer, source, or marker removal:", error);
    }
  };

  const cleanUpMap = async (mapInstance: any) => {
    if (mapAnimation !== null) {
      cancelAnimationFrame(mapAnimation);
      setMapAnimation(null);
    }

    mapInstance.off("click");
    mapInstance.off("mouseenter");
    mapInstance.off("mouseleave");
    mapInstance.off("move");
    mapInstance.off("moveend");
    mapInstance.off("style.load");
    mapInstance.off("render");

    setTimeout(() => {
      if (allMarkers.length > 0) {
        allMarkers.forEach(([marker, markerName]: any) => marker.remove());
        setAllMarkers([]);
      }

      const markerDivs = document.querySelectorAll(".mapboxgl-marker");
      markerDivs.forEach((marker) => {
        marker.remove();
      });
      removeSourcesAndLayers(mapInstance, allLayers, allLayers);
      setAllLayers([]);
      setiÎsLoading(false);
      const el = document.createElement("div");
      el.className = `marker-small`; // Set class based on transportation mode
      // @ts-ignore
      const marker = new mapboxgl.Marker(el)
        // @ts-ignore
        .setLngLat([0, 0])
        .addTo(map);
      // @ts-ignore
      markersdistanceRef.current.push(marker);
    }, 500);
  };

  useEffect(() => {
    if (!mapInstance) return;

    resetStyleAndFeatures(mapInstance);

    mapInstance.resize();
    try {
      mapInstance.flyTo({ center: [7, 48], zoom: 4.3, speed: 2 });
    } catch (error) {
      console.log("error in setting center and zoom", error);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance) return;

    const mapContainerDiv = document.getElementById("map");
    if (mapContainerDiv && mapContainerRef.current) {
      if (mapContainerDiv !== mapContainerRef.current.parentNode) {
        mapContainerDiv.innerHTML = ""; // Clear existing content
        mapContainerDiv.appendChild(mapContainerRef.current);
      }
    }
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance) return;

    const setLanguage = () => {
      const style = mapInstance.getStyle();
      if (style && style.layers) {
        style.layers.forEach((layer) => {
          if (
            layer.type === "symbol" &&
            layer.layout &&
            layer.layout["text-field"]
          ) {
            // Adjust the text-field for each label layer
            mapInstance.setLayoutProperty(layer.id, "text-field", [
              "coalesce",
              ["get", `name_${i18n.language}`], // Try to get the localized name
              ["get", "name"], // Fallback to the default name
            ]);
          }
        });
      }
    };

    setLanguage();
  }, [i18n.language]);

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const data = await fetchSpots(); // Replace with your API endpoint
        setGeoData(data);
        setFilteredGeoData(data);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };

    if (!isLoading) {
      fetchGeoData();
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const data = await fetchSpotTrips(); // Replace with your API endpoint
        setTrips(data);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };

    fetchTrips();
  }, []);

  const getElevation = (featureId: any, elevation_profile: any) => {
    // if (!mapInstance || chapterIndex === null || !mapFeatures || !mapFeatures.features) return;
    const feature = routeSegmentsGeoJSON.features.find(
      (f: any) => f.properties.id === featureId
    );

    // if (!feature || feature.geometry.type !== "LineString" || !feature.elevation_profile) {
    if (
      !feature ||
      feature.geometry.type !== "LineString" ||
      !elevation_profile
    ) {
      setElevationSummary([null, null, null]);
      setShowChart(false);
      showElevationRef.current = false;

      return;
    }
    setElevationData(elevation_profile);
    let smoothFactor = 100;
    let smoothTreshold = 0.2;
    if (
      feature.properties.transportation === "hike" ||
      feature.properties.transportation === "run"
    ) {
      smoothFactor = 25;
      smoothTreshold = 0.1;
    }

    // let elevations = feature.elevation_profile.coordinates;  // Smoothen with approx 100m resolution
    let elevations = elevation_profile.coordinates; // Smoothen with approx 100m resolution

    // const lineString = turf.lineString(feature.elevation_profile.coordinates);
    const lineString = turf.lineString(elevation_profile.coordinates);
    const length = turf.length(lineString, { units: "kilometers" });
    const points: any[] = [];
    const labels: any[] = ["0.0"];
    [] = [];
    let totalAscent = 0;
    let totalDescent = 0;
    let previousElevation = elevations[0][2];
    let cumulativeDistance = 0;

    elevations.forEach((coord: any, index: any) => {
      const currentElevation = coord[2];
      const elevationDifference = currentElevation - previousElevation;

      if (Math.abs(elevationDifference) > 0) {
        // Using a 1 meter threshold
        if (elevationDifference > 0) {
          totalAscent += elevationDifference;
        } else {
          totalDescent -= elevationDifference;
        }
      }

      points.push(Math.round(currentElevation));

      if (index > 0) {
        const segmentLength = turf.distance(elevations[index - 1], coord, {
          units: "kilometers",
        });
        cumulativeDistance += segmentLength;
        labels.push(cumulativeDistance.toFixed(1));
      }

      previousElevation = currentElevation;
    });

    const updateMarkerPosition = (pointIndex: any) => {
      // Assuming you have a way to map label to coordinates
      if (pointIndex === -1) {
        // @ts-ignore
        markersdistanceRef.current[0].setLngLat([0, 0]);
      }
      console.log("marker, ", pointIndex, elevation_profile);
      if (elevation_profile) {
        const coordinates = elevation_profile.coordinates[pointIndex];

        if (coordinates) {
          if (markersdistanceRef.current.length > 0) {
            // @ts-ignore
            const markerElement = markersdistanceRef.current[0].getElement();
            markerElement.className = `marker-small`; // Update the class for dynamic icon change
            // @ts-ignore
            markersdistanceRef.current[0].setLngLat([
              coordinates[0],
              coordinates[1],
            ]);
          }
        }
      }
    };

    // Update chart or other display elements here...

    if (chartInstanceRef.current) {
      // @ts-ignore
      chartInstanceRef.current.destroy(); // Destroy the existing chart instance
    }

    if (chartRef.current) {
      // @ts-ignore
      const ctx = chartRef.current.getContext("2d");
      // @ts-ignore
      chartInstanceRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Elevation (m)",
              data: points,
              fill: false,
              borderColor: "#8ABFA3",
              tension: 0.4,
              pointRadius: 0,
              // @ts-ignore
              hitRadius: 15,
              hoverRadius: 5,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: "Distance (km)",
              },
              grid: {
                display: false, // Remove grid lines for the x-axis
              },
            },
            y: {
              title: {
                display: true,
                text: "Elevation (m)",
              },
              grid: {
                display: false,
              },
              ticks: {
                stepSize: 75,
              },
              beginAtZero: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            mode: "index", // Enables indexing by x-axis position
            axis: "x", // Restrict interaction to the x-axis
            intersect: false, // Allow hovering off the line
          },
          onHover: (event, chartElement) => {
            console.log("event", event);
            if (chartElement.length > 0) {
              updateMarkerPosition(chartElement[0].index);
            } else {
              updateMarkerPosition(-1);
            }
          },
        },
      });
    }

    // const geoJsonData = createGeoJSONLine(currentFeature.elevation_profile.coordinates);
    const geoJsonData = createGeoJSONLine(elevation_profile.coordinates);
    setGpxData(geoJsonData);

    setElevationSummary([
      Math.round(totalAscent),
      Math.round(totalDescent),
      length.toFixed(1),
    ]);
  };

  const resetSpots = (data) => {
    markers.forEach((marker) => marker.remove());
    setMarkers([]);
    markerOthers.forEach((marker) => marker.remove());
    setMarkerOthers([]);

    const newMarkers = data.map((feature, index) => {
      const { geometry, properties } = feature;
      if (!geometry.coordinates) return null;

      const el = document.createElement("div");
      el.className = `marker-poi`;
      el.style.backgroundColor = `#ff880a`;
      // el.style.backgroundColor = `${feature.properties.color}`;

      // Add an inner div for the pseudo-element
      const innerDiv = document.createElement("div");
      innerDiv.className = "marker-overlay";
      innerDiv.style.backgroundImage = `url(https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/climbing.svg)`;
      // innerDiv.style.backgroundImage = `url(${feature.properties.icon})`;
      el.appendChild(innerDiv);

      const marker = new mapboxgl.Marker(el)
        .setLngLat(geometry.coordinates)
        .addTo(mapInstance);

      marker.getElement().addEventListener("click", () => {
        setSelectedFeature(feature);
        console.log("feature", feature);

        if (feature.route_segments) {
          const routeGeoJSON = {
            type: "FeatureCollection",
            features: feature.route_segments.map((segment) => {
              let allCoordinates = [];

              if (segment.route_segment.polylines) {
                segment.route_segment.polylines.forEach((encodedPolyline) => {
                  const decodedCoordinates = polyline.decode(encodedPolyline); // Decode polyline
                  allCoordinates = allCoordinates.concat(decodedCoordinates);
                });
              }

              const geometry = segment.route_segment.polylines
                ? { type: "LineString", coordinates: allCoordinates }
                : segment.geometry;

              return {
                type: "Feature",
                geometry: geometry,
                properties: {
                  route_id: segment.route_segment.id,
                  transportation:
                    segment.route_segment.transportation?.name || "unknown",
                  color:
                    transportationColors[
                      segment.route_segment.transportation?.name
                    ] || "#000000",
                },
              };
            }),
          };
          setRouteSegmentsGeoJSON(routeGeoJSON); // Update GeoJSON for route segments
        }
        setSecondaryPOIs(feature.secondary_pois || []);
      });

      return marker;
    });

    setMarkers(newMarkers);
  };

  useEffect(() => {
    if (!mapInstance || !geoData.length) return;

    resetSpots(filteredGeoData);
  }, [filteredGeoData]);

  useEffect(() => {
    if (secondaryPOIs.length === 0) return;

    markers.forEach((marker) => marker.remove());
    setMarkers([]);

    const newMarkers = secondaryPOIs.map((feature, index) => {
      const { geometry, poi } = feature;
      if (!geometry.coordinates) return null;
      console.log("props", poi, feature);

      const el = document.createElement("div");
      el.className = `marker marker-poi`;
      el.style.backgroundColor = `${poi.color}`;

      // Add an inner div for the pseudo-element
      const innerDiv = document.createElement("div");
      innerDiv.className = "marker-overlay";
      innerDiv.style.backgroundImage = `url(${poi.icon})`;
      el.appendChild(innerDiv);

      const marker = new mapboxgl.Marker(el)
        .setLngLat(geometry.coordinates)
        .addTo(mapInstance);

      marker.getElement().addEventListener("click", () => {
        setSelectedPoi(feature);
      });

      return marker;
    });

    setMarkers(newMarkers); // Filter out null markers
  }, [secondaryPOIs]);

  useEffect(() => {
    if (!mapInstance || !routeSegmentsGeoJSON) return;

    // Remove existing route layers and sources
    if (mapInstance.getLayer("route-segments")) {
      mapInstance.removeLayer("route-segments");
    }
    if (mapInstance.getSource("route-segments")) {
      mapInstance.removeSource("route-segments");
    }

    mapInstance.addSource("route-segments", {
      type: "geojson",
      data: routeSegmentsGeoJSON,
    });

    mapInstance.addLayer({
      id: "route-segments",
      type: "line",
      source: "route-segments",
      paint: {
        "line-color": ["get", "color"],
        "line-width": 5,
      },
    });

    const transportMarkers = [];

    routeSegmentsGeoJSON.features.forEach((feature: any) => {
      const lineLength = turf.length(feature, {
        units: "meters",
      });
      const oneThirdLength = lineLength / 3;
      const pointAtOneThird = turf.along(feature, oneThirdLength, {
        units: "meters",
      });

      // Create an HTML element for the marker
      const el = document.createElement("div");
      // @ts-ignore
      el.className = `marker icon-${feature.properties.transportation}`;

      const marker = new mapboxgl.Marker(el)
        // @ts-ignore
        .setLngLat(pointAtOneThird.geometry.coordinates)
        .addTo(map);

      marker.getElement().addEventListener("click", async () => {
        // Wait for the popup to be open and then render the React component
        setElevationData(null);

        if (
          feature.properties.transportation === "hike" ||
          feature.properties.transportation === "run" ||
          feature.properties.transportation === "bike"
        ) {
          setShowChart(true);
          showElevationRef.current = true;
          const routeElevation: any = await fetchSpotRouteElevation(
            feature.properties.route_id
          );
          getElevation(feature.properties.id, routeElevation.elevation_profile);
          console.log("clicked");
        }
      });
      transportMarkers.push(marker);
    });

    setMarkerOthers(transportMarkers);
  }, [routeSegmentsGeoJSON]);

  useEffect(() => {
    if (selectedFeature) {
      fitBoundsToFeatures(mapInstance, routeSegmentsGeoJSON, markers);
    }
  }, [markers, routeSegmentsGeoJSON]);

  const returnToInitial = () => {
    setSelectedFeature(null);
    setSelectedPoi(null);
    mapInstance.flyTo({ center: [7, 48], zoom: 4.3, speed: 2 });
    resetSpots(filteredGeoData);
    setShowChart(false);
    setElevationData(null);
    showElevationRef.current = false;
    setElevationSummary([null, null, null]);
  };
  const returnToSpot = () => {
    setSelectedPoi(null);
  };

  return (
    <div className="w-full h-screen relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <Navbar />

      <div id="map" className="absolute left-0 w-full h-[calc(100vh)]" />
      {isLoading && (
        <div className=" z-[10000] absolute left-0 w-full h-[calc(100vh)] bg-white">
          <LoadingPage />
        </div>
      )}

      <SpotSidebar
        feature={selectedFeature}
        poi={selectedPoi}
        onClose={returnToInitial}
        onReturn={returnToSpot}
        tripsShown={tripsShown}
      />
      <FilterMenu
        onFilterChange={setFilteredGeoData}
        geoData={geoData}
        trips={trips}
        setTripsShown={setTripsShown}
      />

<div className="absolute bottom-10 right-10">
<TooltipUI showArrow={true} content={t("spot.add-spot")} placement='left'>

<IconButton aria-label="delete" size="large" className="!bg-secondary !shadow-xl transition-all hover:scale-95 ring-2 ring-slate-100" onClick={() => {
  navigate("/add-new-spot/climbing");
}}>
<AddIcon sx={{color: 'white'}}/>
</IconButton>
</TooltipUI>
</div>


      <div
        className="z-[10000] bottom-10 left-12 absolute max-w-[500px] w-[calc(100vw-550px)]"
        style={{
          height: `${showChart ? "250px" : "0"}`,
          visibility: `${showChart ? "visible" : "hidden"}`,
        }}
      >
        <div className={`bg-white rounded-xl shadow-all box-border h-full`}>
          {elevationSummary[0] !== null ? (
            <>
              <div className="flex flex-row gap-3 justify-between pt-2 mr-2">
                <IconButton
                  onClick={() => {
                    setShowChart(false);
                    showElevationRef.current = false;
                    setElevationSummary([null, null, null]);
                  }}
                >
                  {" "}
                  <Close />
                </IconButton>
                <div className="w-full flex flex-row gap-3 justify-center items-center">
                  <div>
                    <i className="fa-solid fa-arrow-trend-up"></i>{" "}
                    {elevationSummary[0]}m
                  </div>
                  <div>
                    <i className="fa-solid fa-arrow-trend-down"></i>{" "}
                    {elevationSummary[1]}m
                  </div>
                  <div>
                    <i className="fa-solid fa-arrows-left-right"></i>{" "}
                    {elevationSummary[2]}km
                  </div>
                </div>

                <Button
                  onClick={() => downloadGPX(gpxData)}
                  color="primary"
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                >
                  {" "}
                  GPX
                </Button>
              </div>
            </>
          ) : (
            <div className="w-full h-full flex flex-col gap-3 items-center justify-center">
              <div className="graph-border overflow-hidden">
                <div className="graph0"></div>
                <div className="graph"></div>
                <div className="graph2"></div>
                <div className="graph3"></div>
              </div>
              {t("retrieving-gpx")}
            </div>
          )}

          <div className="h-[200px] mr-2 box-border">
            <canvas ref={chartRef} id="elevation-chart"></canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClimbingSpotsMap;
