import { useGoogleLogin } from '@react-oauth/google';
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';


import axios from 'axios';
import { AuthContext } from './AuthContext';
import { useNavigate } from "react-router-dom";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface GoogleSignUpProps {
  onSignUpSuccess: (isAuthenticated: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  text: string;
}

export const GoogleSignUp: React.FC<any> = ({ onSignUpSuccess, setIsLoading, text, checked=true
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  if (!authContext) {
    // Handle the null context scenario, e.g., return null, redirect, or show an error
    console.error('Authentication context not available');
    return null;
  }
  const { user } = authContext;

  const navigate = useNavigate();
  const [isAuthChecked, setIsAuthChecked] = useState(false);


  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsLoading(true);
        const response = await axios.post(`${API_BASE_URL}/members/rest-auth/google/`, {
          access_token: tokenResponse.access_token,
        });
        localStorage.setItem('token', response.data.token);
        authContext?.setIsAuthenticated(true);
        // Fetch the user data here and set it
        onSignUpSuccess(true);
        setIsAuthChecked(true)
      } catch (error) {
        console.error('Google Sign-Up error', error);
        onSignUpSuccess(false);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => console.log('Sign-Up failed'),
  });

  useEffect(() => {
    if (isAuthChecked && user) {
      const redirect = new URLSearchParams(location.search).get('redirect');
      navigate(redirect || '/');
    }
  }, [isAuthChecked, user]);

  return (
    <div className='flex flex-col gap-4 w-full h-full'>

    <button onClick={(e) => {
      e.preventDefault();
      if (checked) {
        googleLogin()
        console.log('clicked')

      }

     } } className={`w-full h-full py-2 px-4 flex justify-center items-center  ${checked ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200' : 'bg-gray'} text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg`} disabled={checked ? false : true}>
    <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z">
        </path>
    </svg>
    {text}
    </button>

    </div>
  );
};
