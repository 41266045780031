import { Feature, FeatureCollection, Geometry } from 'geojson';
import bbox from '@turf/bbox';
import { lineColors, transportationColorsDirection } from './mapStyles';
import * as turf from '@turf/turf';
import { mean } from 'mathjs';
import { TFunction } from 'i18next'; // Import TFunction type if using TypeScript
// @ts-ignore
import polyline from '@mapbox/polyline';
// @ts-ignore
import togpx from "togpx";

export const convertArrayBufferToBase64 = (buffer: ArrayBuffer): Promise<string> => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([buffer], { type: 'image/jpeg' }); // Adjust the type if necessary
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data.split(',')[1]); // Split to remove data URL scheme
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  export const getSimplificationTolerance = (zoom: number): number => {
    // Example logic, adjust as needed
    if (zoom >= 10) return 0.001; // Less simplification for closer views
    if (zoom < 10 && zoom >= 5) return 0.01; // Moderate simplification
    return 0.1; // More simplification for distant views
  };


  export function calculateBoundingBox(features: FeatureCollection): [number, number, number, number] | null {
    try {
      //@ts-ignore
      return bbox(features);
    } catch (error) {
      console.error('Failed to calculate bounding box', error);
      return null;
    }
  }

  export function getBoundingBoxCenter(bounds: [number, number, number, number]): [number, number] {
    return [
      (bounds[0] + bounds[2]) / 2, // Longitude
      (bounds[1] + bounds[3]) / 2, // Latitude
    ];
  }


  export const timeToSliderValue = (days: number) => {
    if (days <= 14) return days * (100 / 3) / 14; // 0-7 days to 0-33%
    if (days <= 60) return 33 + ((days - 14) * (100 / 3) / (60 - 14)); // 7-30 days to 33-66%
    if (days <= 365) return 66 + ((days - 60) * (100 / 3) / (365 - 60)); // 30-365 days to 66-100%
    return 100; // >365
  };


  export const sliderValueToTime = (value: number) => {
    if (value <= 33) return value * 14 / (100 / 3); // 0-33% to 0-7 days
    if (value <= 66) return 14 + ((value - 33) * (60 - 14) / (100 / 3)); // 33-66% to 7-30 days
    if (value <= 100) return 60 + ((value - 66) * (365 - 60) / (100 / 3)); // 66-100% to 30-365 days
    return 365; // > 1 year, edge case handling
  };

  export const calculateLabel = (value: number, t: TFunction) => {
    const days = sliderValueToTime(value);
    if (days < 14) return t('days', { count: Math.round(days) }); // e.g., "{count} day(s)"
    if (days < 60) return t('weeks', { count: Math.round(days / 7) }); // e.g., "{count} week(s)"
    if (days <= 365) return t('months', { count: Math.round(days / 30) }); // e.g., "{count} month(s)"
    return t('over_a_year'); // e.g., "> 1 year"
  };

  export const getMarks = (t: TFunction) => [
    { value: timeToSliderValue(1), label: t('one_day') },
    { value: timeToSliderValue(14), label: t('two_weeks') },
    { value: timeToSliderValue(60), label: t('two_months') },
    { value: timeToSliderValue(345), label: t('over_a_year') },
  ];


  export function createPOIFeatures(chapters: any) {
    const poiFeatures: any = [];

    chapters.forEach((chapter: any) => {
      let globalIndex = 0;
      chapter.chapter_poi.forEach((poi: any) => {
        const poiFeature = {
          type: "Feature",
          properties: {
            id: poi.id,
            index: globalIndex,
            title: poi.title,
            content: poi.content,
            poi: poi.poi.name, // Or any other POI properties you want to include
            icon: poi.poi.icon,
            color: poi.poi.color,
            chapter: chapter.id,
            images: poi.images,
          },
          geometry: poi.geometry,
        };
        poiFeatures.push(poiFeature);
      });
      globalIndex++;

    });

    return poiFeatures;
  }

  export function createGeoJSONFeatureCollection(data: any) {
    const routeFeatures: any = [];
    let globalIndex = 0;

    data.chapters.forEach((chapter: any) => {
      chapter.route_segments.forEach((segment: any) => {
        let allCoordinates: any[] = [];

        segment.polylines.forEach((encodedPolyline: string) => {
          const decodedCoordinates = polyline.decode(encodedPolyline);
          allCoordinates = allCoordinates.concat(decodedCoordinates);
        });

        const geoJSONGeometry = {
          type: "LineString",
          coordinates: allCoordinates
        };

        const feature = {
          type: "Feature",
          properties: {
            transportation: segment.transportation.name,
            id: globalIndex,
            chapter: chapter.id,
            route_id: segment.id
          },
          // geometry: segment.geometry,
          geometry: geoJSONGeometry,
          elevation_profile: segment.elevation_profile,
        };
        routeFeatures.push(feature);
        globalIndex++;
      });
    })
      // Now include the POI features
  const poiFeatures = createPOIFeatures(data.chapters);

  const featureCollection = {
    type: "FeatureCollection",
    features: [...routeFeatures, ...poiFeatures], // Combine route and POI features
  };

  return featureCollection;
}

export function createChapterPOIFeatures(chapter: any) {
  const poiFeatures: any = [];
  let globalIndex = 0;

  chapter.chapter_poi.forEach((poi: any) => {
      const poiFeature = {
        type: "Feature",
        properties: {
          id: globalIndex,
          title: poi.title,
          content: poi.content,
          poi: poi.poi.name,
          icon: poi.poi.icon,
          color: poi.poi.color,
          chapter: typeof chapter.order === 'number' ? chapter.order : poi.chapter,
          images: poi.images,
        },
        geometry: poi.geometry,
      };

      poiFeatures.push(poiFeature);
      globalIndex++;
    });

  return poiFeatures;
}

export function createChapterGeoJSONFeatureCollection(chapter: any) {
  const routeFeatures: any = [];
  let globalIndex = 0;


    chapter.route_segments.forEach((segment: any) => {
      let allCoordinates: any[] = [];

      let geoJSONGeometry

      if (segment.polylines) {
        segment.polylines.forEach((encodedPolyline: string) => {
          const decodedCoordinates = polyline.decode(encodedPolyline);
          allCoordinates = allCoordinates.concat(decodedCoordinates);
        });

        geoJSONGeometry = {
          type: "LineString",
          coordinates: allCoordinates
        };
      }


      const feature = {
        type: "Feature",
        properties: {
          transportation: {name: segment.transportation? segment.transportation.name : segment.properties.transportation.name},
          id: globalIndex,
          chapter: chapter.order,
        },
        geometry: geoJSONGeometry ? geoJSONGeometry : segment.geometry,
      };
      routeFeatures.push(feature);
      globalIndex++;
    });
    // Now include the POI features
const poiFeatures = createChapterPOIFeatures(chapter);

const featureCollection = {
  type: "FeatureCollection",
  features: [...routeFeatures, ...poiFeatures], // Combine route and POI features
};

return featureCollection;
}



export function createConcatenatedRoutesForBlogs(blogs: any[]) {

  // Function to create multi-route feature for a single blog
  const createMultiRouteFeatureForBlog = (blogData: any, index: number) => {
    const routes: any[] = [];

    // blogData.chapters.forEach((chapter: any) => {
    //   chapter.route_segments.forEach((segment: any) => {
    //     routes.push({
    //       type: "LineString",
    //       coordinates: segment.geometry.coordinates,
    //       properties: {
    //         chapter_id: chapter.id,
    //         segment_id: segment.id,
    //         color: color,
    //       },
    //     });
    //   });
    // });

    // if (blogData.id === 235 ) {
      Object.entries(blogData.polylines).forEach(([transportType, encodedPolylines]: [string, string[]]) => {
        const color = transportationColorsDirection[transportType] || transportationColorsDirection.unknown; // Get color for transport type or default

        encodedPolylines.forEach((encodedPolyline: any) => {
          const decodedCoordinates = polyline.decode(encodedPolyline);

          routes.push({
            type: 'LineString',
            coordinates: decodedCoordinates.map((coord: any) => [coord[0], coord[1]]), // Inverting [lat, lng] to [lng, lat]
            properties: {
              blog_id: blogData.id,
              transport_type: transportType, // Add transport type to properties
              color: color,                  // Assign specific color for transport type
            },
          });
        });
      });
    // } else {

    //   blogData.polylines.forEach((encodedPolyline: any, index: number) => {
    //     const colorIndex = index % lineColors.length;
    //     const color = lineColors[colorIndex];
    //     const decodedCoordinates = polyline.decode(encodedPolyline);
    //     routes.push({
    //       type: 'LineString',
    //       coordinates: decodedCoordinates.map((coord: any) => [coord[0], coord[1]]), // Inverting [lat, lng] to [lng, lat]
    //       properties: {
    //         blog_id: blogData.id,
    //         color: color,
    //       },
    //     });
    //   });
    // }


        // Calculate the total length of the concatenated geometries
        const allCoordinates = routes.reduce((acc, route) => {
          acc.push(...route.coordinates);
          return acc;
        }, []);
        const concatenatedLine = turf.lineString(allCoordinates);
        const totalLength = turf.length(concatenatedLine, { units: 'kilometers' });

    const feature = {
      type: "Feature",
      id: blogData.id,
        author: blogData.author,
        activities: blogData.activities,
        title: blogData.title,
        title_en: blogData.title_en,
        title_fr: blogData.title_fr,
        title_nl: blogData.title_nl,
        summary: blogData.summary,
        summary_en: blogData.summary_en,
        summary_fr: blogData.summary_fr,
        summary_nl: blogData.summary_nl,
        // lineColor: color,
        header_image: blogData.header_image,
        header_image_thumbnail: blogData.header_image_thumbnail,
        likers: blogData.likers,

      route: {
        type: "GeometryCollection",
        geometries: routes,
        totalLength: totalLength, // Add total length as a property
      },
    };

    return feature;
  };

  // Loop through each blog and create a multi-route feature for each
  return blogs.map((blog, index) => createMultiRouteFeatureForBlog(blog, index));
}

export function getLocalizedField(blog: any, field: any, lang:any, user: any) {
  const suffix = lang ? `_${lang}` : '';
  if (blog[field + suffix] === '' || blog[field + suffix] === null || blog[field + suffix] === undefined) {
    return blog[field];

  } else if (user) {
    return blog[field];
  } else {
    return blog[field + suffix]}
}


export const smoothElevation = (coordinates: any, targetResolutionMeters = 100) => {
  const windowSize = calculateWindowSize(coordinates, targetResolutionMeters);
  const smoothedElevations = [];

  for (let i = 0; i < coordinates.length; i++) {
    const start = Math.max(0, i - Math.floor(windowSize / 2));
    const end = Math.min(coordinates.length, i + Math.floor(windowSize / 2) + 1);
    const subset = coordinates.slice(start, end).map((coord: any) => coord[2]);
    const avgElevation = mean(subset);
    smoothedElevations.push([coordinates[i][0], coordinates[i][1], avgElevation]);
  }

  return smoothedElevations;
};

export const calculateWindowSize = (coordinates: any, targetResolutionMeters: any) => {
  const totalLength = turf.length(turf.lineString(coordinates), { units: 'meters' });
  const totalPoints = coordinates.length;
  const avgDistancePerPoint = totalLength / totalPoints;
  const windowSize = Math.round(targetResolutionMeters / avgDistancePerPoint);
  return Math.max(1, windowSize);
};

export const calculateDistancesAndElevations = (coordinates: any, transportMode: any) => {
  const stats = {
    distance: 0,
    ascent: 0,
    descent: 0,
  };

  let previousElevation = coordinates[0][2];
  let ascent = 0;
  let descent = 0;
  let totalDistance = 0;
  const threshold = transportMode === 'hike' || transportMode === 'run' ? 0.1 : 0.2;

  for (let i = 1; i < coordinates.length; i++) {
    const currentElevation = coordinates[i][2];
    const distance = turf.distance(
      turf.point([coordinates[i - 1][0], coordinates[i - 1][1]]),
      turf.point([coordinates[i][0], coordinates[i][1]]),
      { units: 'meters' }
    );
    totalDistance += distance;

    const diff = currentElevation - previousElevation;
    if (Math.abs(diff) > threshold) {
      if (diff > 0) {
        ascent += diff;
      } else {
        descent -= diff;
      }
      previousElevation = currentElevation;
    }
  }

  stats.distance = Math.round(totalDistance / 1000); // Convert to kilometers and round
  stats.ascent = Math.round(ascent); // Round to the nearest integer
  stats.descent = Math.round(descent); // Round to the nearest integer

  return stats;
};


export const getRandomLocation = (lat: any, lon: any, radius = 3000) => {
  const getRandomOffset = (max: any) => Math.random() * max * (Math.random() < 0.5 ? -1 : 1);

  // Convert radius from meters to degrees
  const radiusInDegrees = radius / 111320;

  const latOffset = getRandomOffset(radiusInDegrees);
  const lonOffset = getRandomOffset(radiusInDegrees / Math.cos(lat * (Math.PI / 180)));

  const newLat = lat + latOffset;
  const newLon = lon + lonOffset;

  return [newLat, newLon];
};


export function createSpotPOIFeatures(spot: any) {
  const poiFeatures: any = [];
  let globalIndex = 0;

  spot.secondary_pois.forEach((poi: any) => {
      const poiFeature = {
        type: "Feature",
        properties: {
          id: globalIndex,
          title: poi.title,
          content: poi.content,
          poi: poi.poi.name,
          icon: poi.poi.icon,
          color: poi.poi.color,
          chapter: typeof spot.order === 'number' ? spot.order : poi.chapter,
          images: poi.images,
        },
        geometry: poi.geometry,
      };

      poiFeatures.push(poiFeature);
      globalIndex++;
    });

  return poiFeatures;
}

export function createSpotGeoJSONFeatureCollection(spot: any) {
  const routeFeatures: any = [];
  let globalIndex = 0;


    spot.route_segments.forEach((segment: any) => {
      let allCoordinates: any[] = [];

      let geoJSONGeometry

      if (segment.polylines) {
        segment.polylines.forEach((encodedPolyline: string) => {
          const decodedCoordinates = polyline.decode(encodedPolyline);
          allCoordinates = allCoordinates.concat(decodedCoordinates);
        });

        geoJSONGeometry = {
          type: "LineString",
          coordinates: allCoordinates
        };
      }


      const feature = {
        type: "Feature",
        properties: {
          transportation: {name: segment.transportation? segment.transportation.name : segment.properties.transportation.name},
          id: globalIndex,
          chapter: spot.order,
        },
        geometry: geoJSONGeometry ? geoJSONGeometry : segment.geometry,
      };
      routeFeatures.push(feature);
      globalIndex++;
    });
    // Now include the POI features
const poiFeatures = createSpotPOIFeatures(spot);

console.log('poiFeatures', poiFeatures);

const featureCollection = {
  type: "FeatureCollection",
  features: [
    ...routeFeatures,
    ...poiFeatures,
    ...(spot.feature ? [spot.feature] : []), // Add spot.feature only if it's not null
  ],
};

return featureCollection;
}


export const downloadGPX = (geojsonData: any) => {
  const gpxData = togpx(geojsonData, {
    creator: "slostr",
    featureTitle: (props: any) => `Route ${props.id}`,
    featureDescription: (props: any) => `${props.description}`,
  });

  const blob = new Blob([gpxData], { type: "application/gpx+xml" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "route.gpx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
